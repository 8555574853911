.big-nav{
    display: flex;
    align-items: center;
    background-color: rgb(53,56,57);
  }
  
  .nav-title{
    font-size: 1.5rem;
    margin-bottom: 0;
    margin-top: 1rem!;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  }
  
  .ui.secondary.menu .item{
    color: rgb(211,211,211);
    padding: 2px 2px;
  }
  
  .ui.secondary.menu a.item:hover{
    color: white;
  }
  
  .ui.secondary.menu .active.item{
    color: white;
  }
  
  .ui.secondary.menu .active.item:hover{
    color: white;
  }
  
  #nav-title{
    color: white;
    padding: 10px;
  }

  .ui.secondary.menu{
    width: 100%;
  }
  
  .big-container{
    width: 100%;
    padding: 1rem;
    margin-top: 1rem;
    display: flex!important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #footer{
    margin: 0!important;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(53,56,57);
    position: relative;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
}

#footer .text{
  color: #fff;
}

#footer .icons >i {
  padding-right: 10px;
}

.wide{
    width: 100%!important
}

.post-container{
  display: flex;
  flex-wrap: wrap;
}

.post-card{
  width: 100%!important
}

#single-container {
  margin-top: 1rem;
  padding-top: 1rem;
  background-color: grey;
  border-radius: 1rem;
  color: white;
  margin-bottom: 1rem;
}

#single-username{
  margin-top:5px;
}

.comment-form{
  margin-bottom: 1rem!important;
  background-color: grey;
  border-radius: 5px;
  padding: 1rem;
}

.ui.header.white{
  color: white;
}

.ui.card.preview-card{
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.posts-container{
  margin-bottom: 1rem;
}

.vote-box{
  margin-bottom: 1rem;
}

.single-votes{
  margin-right: 5px;
}

.text-content{
  padding: 1rem;
}